<label class="qty-step-item"
       [class.qtytype-active]="value === part.partNum"
       [class.qtytype-focused]="focused === part.partNum">
  <div style="display:inline-block"
       [formGroup]="selectedProductForm">
    <input kendoRadioButton
           type="radio"
           formControlName="partNum"
           name="partNum"
           value="{{part.partNum}}"
           (blur)="onBlur()"
           (focus)="onFocus(part.partNum)">
    <label *ngIf="!part.emptyQtyTypeLabel" class="radio-button-label">{{ 'lblFixedLength' | translate }}  </label>
  </div>
  <div [formGroup]="qtyItemForm">
    <h4>{{part.partNum}} : {{part.partDescription | uppercase}}</h4>
    <label style="font-weight:bold">{{ 'lblQty' | translate }}:</label>
    <kendo-numerictextbox [style.width.px]="150"
                          style="margin:0 10px;"
                          [spinners]="false"
                          placeholder="{{ 'lblQuantity' | translate }}"
                          format="n0"
                          [decimals]="0"
                          [min]="0"
                          [maxlength]="7"
                          (blur)="onBlur()"
                          (focus)="onFocus(part.partNum)"
                          formControlName="qty"></kendo-numerictextbox>
    <kendo-dropdownlist style="width:100px"
                        [svgIcon]="chevronDown"
                        fillMode="outline"
                        (blur)="onBlur()"
                        (focus)="onFocus(part.partNum)"
                        [data]="part.partUOMs"
                        textField="uomDesc"
                        valueField="uomCode"
                        [valuePrimitive]="true"
                        formControlName="sellingUOM"> </kendo-dropdownlist>
  </div>

  <div style="margin-bottom: 10px; margin-top: 10px">
    <strong>{{'lblTotalQtySold' | translate }} : </strong>
    <span>{{qtyItemForm.controls.totalSellingQty.value}} {{qtyItemForm.controls.sellingUOMDesc.value}}</span>
  </div>
</label>
