<form #sumForm="ngForm" *ngIf="!(orderFacade.saved$ | async)">
    <h1 style="color: #0090B4 ">{{ 'lblSummary' | translate }}</h1>

    <div style="background-color:white;padding:15px;border-radius:15px;">
        <div style="display: flex; font-weight: bold;margin-left:15px; padding:10px 0; color: #00242d">
            <div style="display:flex; width:75%;">{{ 'lblProducts' | translate }}</div>
            <div style="display: flex; width: 10%; justify-content:center">
                <div>Maibec Express</div>
            </div>
            <div style="display: flex; width: 15%; justify-content:center">
                <div>{{ 'lblTotalQtySold' | translate }}</div>
            </div>
        </div>
        <div *ngFor="let mainProduct of orderSummary.mainProducts; let i = index;"
             style="display:flex; margin-left:15px; flex-flow:column">
            <div style="display:flex; flex-flow:row">
                <div style="display: flex; width: 75%; flex: 0 0 auto; flex-flow: column;">
                    <kendo-expansionpanel title="{{mainProduct.partNum}} - {{mainProduct.partDescription}} - {{mainProduct.colorDesc}}"
                                          style="margin-right:50px;"
                                          [expanded]="mainProduct.comments !== ''"
                                          [svgExpandIcon]="comment"
                                          [svgCollapseIcon]="comment">
                        <kendo-textarea placeholder="{{ 'lblComment' | translate }}" resizable="auto" [rows]="1" [name]="mainProduct.partNum" [(ngModel)]="mainProduct.comments"></kendo-textarea>
                    </kendo-expansionpanel>
                </div>
                <div style="display: flex; width: 10%; justify-content:center">
                    <img title="Maibec Express"
                         style="vertical-align:middle; width:16px;height:16px;"
                         [style]="{'visibility':mainProduct.isMaibecExpress ? '' : 'hidden'}"
                         src="../assets/favicon/favicon-16x16.png" />
                </div>
                <div style="display:flex; width:15%;flex:0 0 auto; justify-content:center">
                    <div style="display:flex;">{{mainProduct.totalSellingQty}} {{mainProduct.sellingUOMDesc}}</div>
                </div>
            </div>
            <div *ngIf="mainProduct.compProducts.length > 0">
                <div style="display:flex; font-weight:bold; margin:15px; padding-top:10px 0;">
                    <div style="flex:0 0 auto; width:75%">{{ 'lblCompProducts' | translate }}</div>
                    <div style="display:flex;flex:0 0 auto; width:25%">
                    </div>
                </div>
                <div *ngFor="let compProduct of mainProduct.compProducts; let i = index;" style="display: flex; margin-left: 15px; margin-bottom: 15px">
                    <div style="display: flex; width: 75%; flex: 0 0 auto; flex-flow: column; ">
                        <kendo-expansionpanel title="{{compProduct.partNum}} - {{compProduct.partDescription}} - {{compProduct.colorDesc}}"
                                              style="margin-right:50px;"
                                              [svgExpandIcon]="comment"
                                              [svgCollapseIcon]="comment">
                            <kendo-textarea placeholder="{{ 'lblComment' | translate }}" resizable="auto" [rows]="1" [name]="compProduct.partNum" [(ngModel)]="compProduct.comments"></kendo-textarea>
                        </kendo-expansionpanel>
                    </div>
                    <div style="display: flex; width: 10%; justify-content:center">
                        <img title="Maibec Express"
                             style="vertical-align:middle; width:16px;height:16px;"
                             [style]="{'visibility':compProduct.isMaibecExpress ? '' : 'hidden'}"
                             src="../assets/favicon/favicon-16x16.png" />
                    </div>
                    <div style="display:flex; width:15%;flex:0 0 auto;justify-content:center">
                        <div style=" text-align: center">{{compProduct.totalSellingQty}} {{compProduct.sellingUOMDesc}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display:flex; justify-content:space-between">
            <button kendoButton
                    class="cnp-button-primary"
                    [disabled]="(orderFacade.saving$ | async)"
                    (click)="sendOrder()">
                <kendo-loader *ngIf="(orderFacade.saving$ | async)" size="small"></kendo-loader>{{ 'btnSaveOrder' | translate }}
            </button>
            <button kendoButton
                    class="cnp-button-primary"
                    (click)="goToQtyStep()">
                {{ 'btnBackToProduct' | translate }}
            </button>
        </div>
    </div>
</form>

<div *ngIf="(orderFacade.saved$ | async) && glbSvc.tokenType.toUpperCase() === 'EPICOR'">
    <h1 style="color: #0090B4">{{ 'lblSaveComplete' | translate }}</h1>
    <div>{{ 'lblCloseBrowser' | translate }}</div>
</div>

<div *ngIf="(orderFacade.saved$ | async) && glbSvc.tokenType.toUpperCase() === 'CNP'">
    <h1 style="color: #0090B4">{{ 'lblPleaseWait' | translate }}</h1>
</div>
